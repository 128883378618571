import React from "react";
import { useParams } from "react-router-dom";
import { URL_IMG } from "../../../../../const/Const";
import RoundImage from "../../../../imagenesReact/RoundImage";

const TeamMainTechnical = ({
	teamTechnical,
	colour,
	ligthcolour,
	textTranslate,
	teamshort,
}) => {
	const { idOrganization } = useParams();

	const orderGroup = teamTechnical.sort(function (a, b) {
		return a.idtechnicalcategory - b.idtechnicalcategory;
	});

	const stylecss = {
		color: {
			backgroundColor: "#" + colour,
		},
	};

	const fontColor = `color-text-${ligthcolour}`;

	return (
		<>
			<div className="pb32-s">
				{teamTechnical && (
					<div className="technicalStatistics">
						{orderGroup?.map((item) => (
							<React.Fragment key={item.id}>
								<ul className="technical">
									<li className="technical__item">
										<div className="technicalcard">
											<div
												className={`technicalcard__number ${fontColor}`}
												style={stylecss.color}
											>
												<p>T</p>
											</div>
											<div className="technicalcard__img">
												<RoundImage
													url={`${URL_IMG}/organizations/${idOrganization}/technicals/${teamshort}/${item.imageUrl}`}
													altImgText="Technical image "
												/>
											</div>
											<div className="technicalcard__name pl16-s">
												{item.namefull}
											</div>
										</div>
									</li>
									<li className="technical__item">
										<div className="thead_mobile">
											{
												textTranslate.Staff.HeadTable
													.Position
											}
										</div>
										<p>
											{
												textTranslate.Staff
													.BodyPosition[
													item.description
												]
											}
										</p>
									</li>
								</ul>
							</React.Fragment>
						))}
					</div>
				)}
			</div>
		</>
	);
};

export default TeamMainTechnical;
