import React, { useContext, useEffect } from "react";
import BannerHome from "../components/main/banner/BannerHome";
import Carousel from "../components/carousel/Carousel";
import ItemCarousel from "../components/carousel/ItemCarousel";
import useScreenSize from "../hook/useScreemSize";
import RequestContext from "../context/RequestContext";
import BannerMain from "../components/main/banner/BannerMain";
import GlobalContext from "../context/GlobalContext";
import SkeletonCarrusel from "../components/carousel/skeleton/SkeletonCarrusel";
import LanguageContext from "../context/LanguageContext";
import useRequest from "../hook/useRequest";
import { URL_DOMIAN, WEB_ALL, URL_IMG } from "../const/Const";
import { ObjectToUrlParam } from "../components/utilities/ObjectToUrlParam";
import { LanguageButton } from "../components/language/LanguageButton";
import useUpdateState from "../hook/useUpdateState";
import useGetCss from "../hook/useGetCss";
import { Link } from "react-router-dom";
import useUser from "../hook/useUser";
import LoginContext from "../context/LoginContext";

const Home = ({ org, orderPage, pageRequest }) => {
	// console.log("render Home");

	const { getAllData } = useRequest();

	const { getCssOrg } = useGetCss();

	const { delStateStorage } = useUpdateState();

	const { textTranslate, handleLanguageButton } = useContext(LanguageContext);

	const { Organizations, Experience, Statistics, Digital } =
		textTranslate?.Home;

	const { data, setData, pagerequest, setSelectOrganization } =
		useContext(GlobalContext);

	// const { isStateUser } = useUser();

	const { message } = useContext(RequestContext);
	const { isAuth } = useContext(LoginContext);

	const { width } = useScreenSize();

	const showClikerti =
		URL_DOMIAN === "https://dev.quadstatistics20.clickerti.es/"
			? true
			: false;

	let dataBodyOptions = { organization_id: org, page_request: pageRequest };

	useEffect(() => {
		getCssOrg(pageRequest);
	}, [getCssOrg, pageRequest]);

	useEffect(() => {
		setData(null);
		delStateStorage(["data"]);

		getAllData(
			WEB_ALL + "?" + ObjectToUrlParam(dataBodyOptions),
			"GET",
			null,
			null
		);
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	function handleOrganizationSelect(selectid) {
		const { name } = data?.Organizations.find(
			(organ) => organ.id === selectid // eslint-disable-line react-hooks/exhaustive-deps
		);

		setSelectOrganization({
			id: selectid,
			name: name,
		});
		window.sessionStorage.setItem(
			"selectorganization",
			JSON.stringify({ id: selectid, name: name })
		);
	}

	return (
		<>
			<div className="secondHeader">
				<div className="contentLogin">
					{isAuth == "notLogged" && (
						<Link className="textogin" to="/login">
							<button
								className="btn btn-outline-primary text-center shadow-none mb-3 button gray-button-color small"
								type="submit"
							>
								Iniciar sesion
							</button>
						</Link>
					)}
					{isAuth == "isLogged" && (
						<>
							<Link className="textogin" to="/dashboard">
								<button
									className="btn btn-outline-primary text-center shadow-none mb-3 button gray-button-color small"
									type="submit"
								>
									Panel de control
								</button>
							</Link>
						</>
					)}
				</div>
				<div className="contentBtnLanguage">
					<LanguageButton
						handleLanguageButton={handleLanguageButton}
					/>
				</div>
			</div>
			<BannerMain />
			<div className="main">
				<div className="contentHome">
					<div className="banner-content h270px-s separator">
						{!data && <SkeletonCarrusel />}
						{message && (
							<div className="wth-100-from-s text-center-from-s">
								No se ha podido conectar con el servidor
							</div>
						)}
						{data === "" && !message && (
							<div className="wth-100-from-s text-center-from-s">
								No se ha encontrado ningún campeonato
							</div>
						)}
						{data && (
							<Carousel
								show={width <= 768 ? 3 : 4}
								infiniteLoop={false}
								data={data.Organizations}
								HomeOrganizations={Organizations}
							>
								{data.Organizations?.map((item) =>
									showClikerti ? (
										<ItemCarousel
											id={item.id}
											nameorganizations={item.name}
											namecountry={item.namecountry}
											key={item.id}
											order={orderPage + 1}
											pagerequest={pagerequest}
											handleOrganizationSelect={
												handleOrganizationSelect
											}
											// HomeOrganizations={
											// 	Organizations.Country
											// }
										/>
									) : (
										item.id !== 1 && (
											<ItemCarousel
												id={item.id}
												nameorganizations={item.name}
												namecountry={item.namecountry}
												key={item.id}
												order={orderPage + 1}
												pagerequest={pagerequest}
												handleOrganizationSelect={
													handleOrganizationSelect
												}
												// HomeOrganizations={
												// 	Organizations.Country
												// }
											/>
										)
									)
								)}
							</Carousel>
						)}
					</div>
					<BannerHome
						pos="izda"
						title={Experience.Title}
						msn={Experience.Text}
						imgsrc={`${URL_IMG}/web/home/young-fan.jpg`}
					/>
					<BannerHome
						pos="dcha"
						title={Statistics.Title}
						msn={Statistics.Text}
						imgsrc={`${URL_IMG}/web/home/web-data.jpg`}
					/>
					<BannerHome
						pos="izda"
						title={Digital.Title}
						msn={Digital.Text}
						imgsrc={`${URL_IMG}/web/home/app-display.jpg`}
					/>
				</div>
			</div>
		</>
	);
};

export default Home;
