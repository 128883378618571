import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import EyeHide from "../../components/img/EyeHide";
import EyeShow from "../../components/img/EyeShow";
import useUser from "../../hook/useUser";
import TitleSubSection from "../../components/titlecomponent/TitleSubSection";
import RequestContext from "../../context/RequestContext";
import LogoName from "../../components/logoname/LogoName";
import LoginContext from "../../context/LoginContext";
import { jwtDecode } from "jwt-decode";
import ModalAuth from "../../components/modals/modalauth/ModalAuth";
import TokenExpireModal from "../component/authorizationcomponent/TokenExpireModal";
import DashContext from "../../context/DashContent";

const ResetPassword = () => {
	const {
		passwordShown,
		password,
		setPassword,
		passwordError,
		setPasswordError,
		c_Password,
		setC_Password,
		c_PasswordShown,
		c_PasswordError,
		setC_PasswordError,
	} = useContext(LoginContext);

	const {
		togglePasswordVisiblity,
		toggleC_PasswordVisiblity,
		resetPasswordUser,
	} = useUser();
	const { message, code } = useContext(RequestContext);
	const testsize = "t4";

	const location = useLocation();
	const [userId, setUserId] = useState();

	const [modalShow, setModalShow] = useState(false);
	const [contentModal, setContentModal] = useState(false);

	const searchParams = new URLSearchParams(location.search);
	const tokenFromUrl = searchParams.get("token");

	const navigate = useNavigate();

	const timestampInSeconds = Math.floor(Date.now() / 1000);

	const HandleClosed = useCallback(() => {
		setModalShow(false);
		navigate("/login");
	}, [navigate]);

	const HandleSendEmail = useCallback(() => {
		setModalShow(false);
		navigate("/login");
	}, [navigate]);

	useEffect(() => {
		const message = {
			titleresponse: "Enlace caducado",
			msnresponse:
				"El enlace se ha caducado. Para recibir otro nuevo enlace, pulsa el boton de solicitud.",
			msnerror: "Error borrando código",
		};

		if (tokenFromUrl) {
			const utj_decoded = jwtDecode(tokenFromUrl);
			setUserId(utj_decoded.sub);

			if (utj_decoded.exp < timestampInSeconds) {
				setModalShow(true);
				setContentModal(
					<TokenExpireModal
						message={message}
						HandleSendEmail={HandleSendEmail}
						HandleClosed={HandleClosed}
					/>
				);
			}
		}
	}, [tokenFromUrl, HandleSendEmail, HandleClosed, timestampInSeconds]);

	const handlePasswordChange = (event) => {
		const newPassword = event.target.value;
		setPassword(newPassword);
		setPasswordError("");
	};

	const handleC_PasswordChange = (event) => {
		const newC_Password = event.target.value;
		setC_Password(newC_Password);
		setC_PasswordError("");
	};

	const handleSubmit = (event) => {
		event.preventDefault();

		let msgPasswordError = "";
		let msgC_PasswordError = "";

		const isValidPassword = (password) => {
			const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).*/;
			return passwordRegex.test(password);
		};

		if (
			password.length < 4 ||
			password.length > 100 ||
			!isValidPassword(password)
		) {
			msgPasswordError =
				"La contraseña debe contener al menos 5 caracteres, una letra minúscula, una letra mayúscula y un número.";
		}
		if (
			c_Password.length < 4 ||
			c_Password.length > 100 ||
			!isValidPassword(c_Password)
		) {
			msgC_PasswordError =
				"La contraseña debe contener al menos 5 caracteres, una letra minúscula, una letra mayúscula y un número.";
		}

		if (password !== c_Password) {
			msgC_PasswordError = "Las contraseñas no son iguales";
		}

		setPasswordError(msgPasswordError);
		setC_PasswordError(msgC_PasswordError);

		if (!msgPasswordError && !msgC_PasswordError) {
			// If there are no errors, proceed with login

			let id = userId;
			let c_password = c_Password;
			let Token = tokenFromUrl;
			resetPasswordUser({ id, password, c_password, Token });
			setPasswordError("");
			setC_PasswordError("");
		}
	};

	return (
		<div className="mainForm">
			{modalShow && (
				<ModalAuth
					elementById="modal"
					modalShow={modalShow}
					setModalShow={setModalShow}
				>
					{contentModal}
				</ModalAuth>
			)}

			<div className="containerForm df-s jcc-s aic-s">
				<div className="cardForm mb3-s">
					<div className="card-body">
						<LogoName />
						<div className="contentTitle mt16-s">
							<TitleSubSection
								title="Cambiar contraseña"
								sizetext={testsize}
							/>
						</div>
						<form onSubmit={handleSubmit}>
							<div className="content-input mb8-s mt8-s dg-s fww-s">
								<label className="form-label">
									Nueva contraseña
								</label>
								<div className="content-password df-s">
									<input
										id="password"
										type={
											passwordShown ? "text" : "password"
										}
										placeholder="Nueva contraseña"
										title="Ingresa una password válida"
										onChange={handlePasswordChange}
										// onInput={handleInputReset}
										value={password}
										className={
											passwordError
												? "w100-s border-danger"
												: "w100-s"
										}
										autoComplete="current-password"
									/>
									<div
										className="contentIconInput"
										// onClick={togglePasswordVisiblity}
										onMouseDown={togglePasswordVisiblity}
										onMouseUp={togglePasswordVisiblity}
									>
										{passwordShown ? (
											<EyeShow
												width="20px"
												height="20px"
												className="input-icon password"
											/>
										) : (
											<EyeHide
												width="20px"
												height="20px"
												className="input-icon password"
											/>
										)}
									</div>
								</div>
							</div>
							<div className="content-danger-extra">
								{passwordError && (
									<p className="text-danger">
										{passwordError}
									</p>
								)}{" "}
								{code === 401 && (
									<p className="text-danger">{message}</p>
								)}
								{code === 202 && message && (
									<p className="text-danger">{message}</p>
								)}
							</div>
							<div className="content-input mb8-s mt8-s dg-s fww-s">
								<label className="form-label">
									Confirmar contraseña
								</label>
								<div className="content-password df-s">
									<input
										id="c_password"
										type={
											c_PasswordShown
												? "text"
												: "password"
										}
										placeholder="Confirmar contraseña"
										title="Ingresa una password válida"
										onChange={handleC_PasswordChange}
										// onInput={handleInputReset}
										value={c_Password}
										className={
											c_PasswordError
												? "w100-s border-danger"
												: "w100-s"
										}
										autoComplete="current-password"
									/>
									<div
										className="contentIconInput"
										onMouseDown={toggleC_PasswordVisiblity}
										onMouseUp={toggleC_PasswordVisiblity}
									>
										{c_PasswordShown ? (
											<EyeShow
												width="20px"
												height="20px"
												className="input-icon password"
											/>
										) : (
											<EyeHide
												width="20px"
												height="20px"
												className="input-icon password"
											/>
										)}
									</div>
								</div>
							</div>
							<div className="content-danger-extra">
								{c_PasswordError && (
									<p className="text-danger">
										{c_PasswordError}
									</p>
								)}{" "}
								{code === 401 && (
									<p className="text-danger">{message}</p>
								)}
								{code === 202 && message && (
									<p className="text-danger">{message}</p>
								)}
							</div>
							<div className="df-s jcc-s m16-s ">
								<button
									className="btn btn-outline-primary text-center shadow-none mb-3 button gray-button-color small"
									type="submit"
								>
									Aceptar
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ResetPassword;
