import React from "react";
import Logo from "../img/Logo";

const LogoName = () => {
	return (
		<div className="contentLogo">
			<Logo height="32px" className="iconLogo" />
			<div className="textLogo bigger">QuadStatistic</div>
		</div>
	);
};

export default LogoName;
