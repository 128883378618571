import { useCallback, useContext } from "react";
import LanguageContext from "../../../context/LanguageContext";

const useGetPathBreadcrumbs = () => {
	const { textTranslate } = useContext(LanguageContext);

	const { Breadcrumb } = textTranslate;

	const { Home, Seasons, Champions, Schedule, Match } = Breadcrumb;

	const constructorCrumbs = useCallback(
		(params, orderPage, page_request) => {
			const basicCrumb = (orderPage, page_request) => {
				let crumbBasic;
				switch (orderPage) {
					case 0:
						crumbBasic = {
							name: Home,
							order: "0",
							path: "/",
						};
						break;
					case 1:
						page_request === 1
							? (crumbBasic = {
									name: Seasons,
									order: "1",
									path: "/seasons",
							  })
							: (crumbBasic = {
									name: Seasons,
									order: "1",
									path: "/",
							  });
						break;
					case 2:
						crumbBasic = {
							name: Champions,
							order: "2",
							path: "/champions",
						};
						break;
					case 3:
						crumbBasic = {
							name: Schedule,
							order: "3",
							path: "/schedule",
						};
						break;
					case 4:
						crumbBasic = {
							name: Match,
							order: "4",
							path: "/match",
						};
						break;
					default:
						crumbBasic = {};
						break;
				}
				return crumbBasic;
			};

			let crumbs = [];
			for (let i = orderPage; i >= 0; i--) {
				let concaParams = [];
				if (i > 1 && page_request > 1) {
					let paramSize = Object.entries(params).length;
					Object.entries(params).forEach(([key, value], index) => {
						if (i + 2 > index && i !== paramSize) {
							if (key !== "orderPage") {
								concaParams.push(`${value}`);
							} else {
								concaParams.push(i);
							}
						}
					});

					const currentcrumb = basicCrumb(i, page_request);
					currentcrumb.path =
						currentcrumb.path + "/" + concaParams.join("/");

					crumbs.unshift(currentcrumb);
				}
				if (i > 0 && page_request === 1) {
					let paramSize = Object.entries(params).length;
					Object.entries(params).forEach(([key, value], index) => {
						if (i + 2 > index && i !== paramSize) {
							if (key !== "orderPage") {
								concaParams.push(`${value}`);
							} else {
								concaParams.push(i);
							}
						}
					});

					const currentcrumb = basicCrumb(i, page_request);
					currentcrumb.path =
						currentcrumb.path + "/" + concaParams.join("/");

					crumbs.unshift(currentcrumb);
				} else if (i === 1 && page_request > 1) {
					crumbs.unshift(basicCrumb(i));
				} else if (i === 0 && page_request === 1) {
					crumbs.unshift(basicCrumb(i));
				}
			}
			return crumbs;
		},
		[Champions, Home, Match, Schedule, Seasons]
	);

	return { constructorCrumbs };
};

export default useGetPathBreadcrumbs;
