import React, { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import Logout from "../img/Logout";
import useUser from "../../hook/useUser";

const Avatar = ({ name }) => {
	const [avatarMenuShow, setAvatarMenuShow] = useState(false);
	const [avatarClass, setAvatarClass] = useState("is-none-from-s");
	const [isMounted, setIsMounted] = useState(false);
	const { logout } = useUser();

	const containerRef = useRef(null);

	useEffect(() => {
		if (isMounted) {
			setAvatarClass(
				avatarMenuShow ? "avatarmenu show" : "avatarmenu collapsed"
			);
		}
	}, [avatarMenuShow, isMounted]);

	useEffect(() => {
		setIsMounted(true);
	}, []);

	useEffect(() => {
		const handleClickOutside = (event) => {
			// Si se hace clic fuera del contenedor y del botón de activación y de la inicial, ocultar el contenedor
			if (
				containerRef.current &&
				!containerRef.current.contains(event.target) &&
				event.target.id !== "UserButton" &&
				event.target.id !== "initial"
			) {
				setAvatarMenuShow(false);
			}
		};

		// Agregar el event listener al montar el componente
		document.addEventListener("mousedown", handleClickOutside);

		// Limpiar el event listener al desmontar el componente
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	// Función para obtener las iniciales
	const obtenerIniciales = (name) => {
		const names = name.split(" ");

		if (names.length === 1) {
			// Si solo hay un name, devolver las iniciales de ese name
			return names[0].charAt(0).toUpperCase();
		} else if (names.length >= 2) {
			// Si hay dos o más names, devolver las iniciales de los dos primeros
			return (
				names[0].charAt(0).toUpperCase() +
				names[1].charAt(0).toUpperCase()
			);
		} else {
			// En caso de que el name esté vacío o no sea una cadena válida
			return "";
		}
	};

	const showUserMenu = (event) => {
		event.preventDefault();
		setAvatarMenuShow(!avatarMenuShow);
	};

	const handleLogout = (event) => {
		event.preventDefault();
		logout();
	};

	// Obtener las iniciales
	const iniciales = obtenerIniciales(name);

	return (
		<div className="contentAvatar gces1-s dg-s">
			<div className="avatar" id="UserButton" onClick={showUserMenu}>
				<span id="initial" className="initial">
					{iniciales}
				</span>
			</div>
			<div ref={containerRef} className={avatarClass}>
				<ul>
					<li className="itemMenuLogout">
						<NavLink
							to="/login"
							onClick={handleLogout}
							className="iconLogout"
						>
							<Logout width="12px" fill="#53585b" />
							<p className="textLogout smaller">Cerrar Sesión</p>
						</NavLink>
					</li>
				</ul>
			</div>
		</div>
	);
};

export default Avatar;
