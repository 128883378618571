import React, { Fragment } from "react";
import { useParams } from "react-router-dom";
import { LOGO_NOT_FOUND, URL_IMG } from "../../../const/Const";
import RoundImage from "../../imagenesReact/RoundImage";

const LeadersSection = (props) => {
	const { idOrganization } = useParams();
	return (
		<>
			<div className="logo">
				<img
					src={
						props.logo === null || ""
							? LOGO_NOT_FOUND
							: `${URL_IMG}/organizations/${idOrganization}/teams/${props.team}/${props.logo}`
					}
					alt="Logo equipo local"
				/>
			</div>
			<RoundImage
				url={props.image}
				altImgText="Leeder image"
				leader={props.leader}
			/>
			<div className="name ellipsis">{props.name}</div>
			<div className="number">{props.value}</div>
		</>
	);
};

export default LeadersSection;
