import React from "react";
import { IsWhite } from "../../utilities/WatchColor";
import ScoreCard from "./ScoreCard";
import ScoreCardSpecial from "./ScoreCardSpecial";
import ScorePoints from "./ScorePoints";

const ScoreBoard = ({ matchData, selectMatchData, textTranslate }) => {
	return (
		<>
			{matchData && (
				<div className="scoreboard">
					<div className="scoreboard__home is-none-to-lg ">
						<ScoreCard
							team={selectMatchData.nameteam1}
							colourteam={IsWhite(
								selectMatchData.colourteam1.slice(3)
							)}
							logoteam={selectMatchData.logoteam1}
							shortname={selectMatchData.localshortname}
						/>
					</div>
					<div className="scoreboard__points">
						<ScorePoints
							matchscore={matchData}
							state={matchData.matchstatus}
							localteam={matchData.team1}
							logolocalteam={selectMatchData.logoteam1}
							colourteam1={IsWhite(
								selectMatchData.colourteam1.slice(3)
							)}
							shortnamelocal={selectMatchData.localshortname}
							visitorteam={matchData.team2}
							logovisitorteam={selectMatchData.logoteam2}
							colourteam2={IsWhite(
								selectMatchData.colourteam2.slice(3)
							)}
							textTranslate={textTranslate}
							shortnamevisitor={selectMatchData.visitorshortname}
						/>
					</div>
					<div className="scoreboard__visitor is-none-to-lg ">
						<ScoreCardSpecial
							team={selectMatchData.nameteam2}
							colourteam={IsWhite(
								selectMatchData.colourteam2.slice(3)
							)}
							logoteam={selectMatchData.logoteam2}
							shortname={selectMatchData.visitorshortname}
						/>
					</div>
				</div>
			)}
		</>
	);
};

export default ScoreBoard;
