import React from "react";
import { useParams } from "react-router-dom";
import { LOGO_NOT_FOUND, URL_IMG } from "../../../const/Const";

const VisitorSpecialCard = ({ logo2, teams2, teamshort, shortvalue }) => {
	const { idOrganization } = useParams();

	let valueName;
	let valueShort;
	if (shortvalue) {
		valueName = "card__name card__name--right is-none-to-ipad";
		valueShort = "card__name card__name--right is-none-from-ipad";
	} else {
		valueName = "card__name card__name--right";
		valueShort = "card__name card__name--right is-none-from-s";
	}

	return (
		<div className="card card--right">
			<div className="card__logo card__logo--right">
				<img
					className="w32px-s-i"
					// src={logo2}
					src={
						logo2 === null || ""
							? LOGO_NOT_FOUND
							: `${URL_IMG}/organizations/${idOrganization}/teams/${teamshort}/${logo2}`
					}
					alt="Logo equipo visitante"
				/>
			</div>
			<p className={valueName}>
				{teams2 === null || "" ? "VISITANTE" : teams2}
			</p>
			<p className={valueShort}>
				{teamshort === null || "" ? "VISITANTE" : teamshort}
			</p>
		</div>
	);
};

// MatchLocalCard.propTypes = {
//     teams2: PropTypes.string.isRequired,
//     logo12 PropTypes.string.isRequired,

// };

// VisitorSpecialCard.defaultProps = {
// 	teams2: "No encontrado",
// 	logo2: "https://quadstatistics.clickerti.es/not-found/image-not-found.jpg",
// };

export default VisitorSpecialCard;
