import React, { createContext, useState, useMemo, useCallback } from "react";
import ES from "../components/language/translate/es.json";
import EN from "../components/language/translate/en.json";

const LanguageContext = createContext({});

const initialLanguage =
	window.navigator.language  === "es-ES"
		? true
		: false;

export function LanguageContextProvider({ children }) {
	//varible que hace el cambio de language
	const [togleLanguage, setTogleLanguage] = useState(
		JSON.parse(window.sessionStorage.getItem("toglelanguage"))
			? JSON.parse(window.sessionStorage.getItem("toglelanguage"))
			: initialLanguage
	);

	//variable que almacena language seleccionado
	const [textTranslate, setTextTranslate] = useState(togleLanguage ? ES : EN);

	const handleLanguageButton = useCallback(() => {
		setTogleLanguage(!togleLanguage);
		window.sessionStorage.setItem(
			"toglelanguage",
			JSON.stringify(!togleLanguage)
		);
		!togleLanguage ? setTextTranslate(ES) : setTextTranslate(EN);
	}, [togleLanguage, setTogleLanguage, setTextTranslate]);

	const value = useMemo(
		() => ({
			textTranslate,
			setTextTranslate,
			handleLanguageButton,
			togleLanguage,
			setTogleLanguage,
		}),
		[textTranslate, handleLanguageButton, togleLanguage]
	);

	return (
		<LanguageContext.Provider value={value}>
			{children}
		</LanguageContext.Provider>
	);
}

export default LanguageContext;
