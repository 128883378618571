import React from "react";
import LocalCardWtihColor from "../card/LocalCardWithColor";
import VisitorCardWithColor from "../card/VisitorCardWithColor";
import {  URL_IMG } from "../../../const/Const";
import { IsWhite } from "../../utilities/WatchColor";
import { useParams } from "react-router-dom";
import RoundImage from "../../imagenesReact/RoundImage";

const HistoryMatch = ({ historyByMatch, selectMatchData, textTranslate }) => {
	const { idOrganization } = useParams();

	const localshortname = selectMatchData.localshortname;
	const visitorshortname = selectMatchData.visitorshortname;

	const stylecss1 = {
		color: {
			backgroundColor:
				"#" + IsWhite(selectMatchData.colourteam1.slice(3)),
		},
	};

	const stylecss2 = {
		color: {
			backgroundColor:
				"#" + IsWhite(selectMatchData.colourteam2.slice(3)),
		},
	};

	const periods = [];

	if (historyByMatch && historyByMatch.length !== 0) {
		for (
			let i = 1;
			i <= historyByMatch[historyByMatch.length - 1].period;
			i++
		) {
			if (i <= selectMatchData.period) {
				i < historyByMatch[historyByMatch.length - 1].period ||
				selectMatchData.state === "FINISH"
					? periods.push(
							<li
								className="historyScore__item historyScore__item--finish"
								key={i}
							>
								{i}
							</li>
					  )
					: periods.push(
							<li
								className="historyScore__item historyScore__item--play"
								key={i}
							>
								{i}
							</li>
					  );
			} else {
				i < historyByMatch[historyByMatch.length - 1].period ||
				selectMatchData.state === "FINISH"
					? periods.push(
							<li
								className="historyScore__item historyScore__item--finish"
								key={i}
							>
								E{i - historyByMatch.periodnumber}
							</li>
					  )
					: periods.push(
							<li
								className="historyScore__item historyScore__item--play"
								key={i}
							>
								E{i - historyByMatch.periodnumber}
							</li>
					  );
			}
		}
	}

	return (
		<>
			<div className="historyMatch pb100-fold">
				{historyByMatch && historyByMatch.length !== 0 && (
					<div className="historyMatch__header h60px-lg">
						<div className="cardTeam">
							<LocalCardWtihColor
								colourteam={IsWhite(
									selectMatchData.colourteam1.slice(3)
								)}
								teamname={selectMatchData.nameteam1}
								logo={selectMatchData.logoteam1}
								teamshort={selectMatchData.localshortname}
							/>
							<VisitorCardWithColor
								colourteam={IsWhite(
									selectMatchData.colourteam2.slice(3)
								)}
								teamname={selectMatchData.nameteam2}
								logo={selectMatchData.logoteam2}
								teamshort={selectMatchData.visitorshortname}
							/>
						</div>
					</div>
				)}
				<div className="vertical__line h30px-s">
					<div className="vertical-line"></div>
				</div>
				{historyByMatch && historyByMatch.length !== 0 && (
					<div className="historyMatch__score">
						<div className="historyScore">
							<div className="historyScore__number">
								{
									historyByMatch[historyByMatch.length - 1]
										.scorelocal
								}
							</div>
							<div className="historyScore__period">
								<div className="historyScore__text">
									{textTranslate.HistoryScorePeriod}
								</div>
								<ul className="historyScore__list">
									{periods}
								</ul>
							</div>
							<div className="historyScore__number">
								{
									historyByMatch[historyByMatch.length - 1]
										.scorevisitor
								}
							</div>
						</div>
					</div>
				)}
				{historyByMatch.length !== 0 &&
					historyByMatch?.map((item, index) => (
						<React.Fragment key={selectMatchData}>
							<div className="vertical__line h20px-s">
								<div className="vertical-line"></div>
							</div>
							{item.nameplayer1 !== "" && (
								<div
									className="historyMatch__list"
									key={selectMatchData}
								>
									<div className="historyMatch__item h50px-s">
										<div className="card h50px-s">
											<div
												className="card__color w6px-s"
												style={stylecss1.color}
											></div>
											<div className="card__img w32px-s-i">
												<RoundImage
													url={`${URL_IMG}/organizations/${idOrganization}/players/${localshortname}/${item.img1}`}
													altImgText="Player image "
												/>
											</div>
											<div className="card__name card__name--bold">
												{item.unifornumber1},{" "}
												{item.nameplayer1},
											</div>
											<div className="card__action">
												{
													textTranslate.Actions[
														item.accion
													]
												}
											</div>
										</div>
										<div className="partialTime partialTime--right">
											<div className="partialTime__period">
												{item.period >= 5
													? `E${item.period - 4}`
													: `P${item.period}`}
											</div>
											<div className="partialTime__time partialTime__time">
												{item.minutematch}
											</div>
										</div>
									</div>
								</div>
							)}
							{item.nameplayer2 !== "" && (
								<div className="historyMatch__list">
									<div className="historyMatch__item historyMatch__item--right h50px-s">
										<div className="partialTime partialTime--left">
											<div className="partialTime__period">
												{item.period >= 5
													? `E${item.period - 4}`
													: `P${item.period}`}
											</div>
											<div className="partialTime__time partialTime__time">
												{item.minutematch}
											</div>
										</div>
										<div className="card card--right h50px-s">
											<div className="card__name card__name--right card__name--bold">
												{item.unifornumber2},{" "}
												{item.nameplayer2},
											</div>
											<div className="card__action card__action--right">
												{
													textTranslate.Actions[
														item.accion
													]
												}
											</div>
											<div className="card__img card__img--right w32px-s-i">
												<RoundImage
													url={`${URL_IMG}/organizations/${idOrganization}/players/${visitorshortname}/${item.img2}`}
													altImgText="Player image "
												/>
											</div>
											<div
												className="card__color card__color--right w6px-s"
												style={stylecss2.color}
											></div>
										</div>
									</div>
								</div>
							)}
						</React.Fragment>
					))}
			</div>
		</>
	);
};

export default HistoryMatch;
