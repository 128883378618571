import React from "react";
import { useParams } from "react-router-dom";
import { URL_IMG } from "../../../const/Const";
import RoundImage from "../../imagenesReact/RoundImage";

const RefereeMatch = ({ dataReferee, textTranslate }) => {
	const { idOrganization } = useParams();

	return (
		<>
			<div className="contentRefereesMatch df-s w100-s jcc-s gg1-s">
				{dataReferee?.map((item, index) => (
					<React.Fragment key={item.id}>
						<div className="referee df-s w30-lg w50-s jcc-s gg1-s">
							<div className="playercard__img acc-s dg-s">
								<RoundImage
									url={`${URL_IMG}/organizations/${idOrganization}/referees/${item.img_referee}`}
									altImgText="Referees image "
								/>
							</div>
							<div className="contentNameReferee">
								<div className="refereeName">
									<b>{item.name_referee}</b>
								</div>
								<div className="refereeCategory supersmall">
									{textTranslate[item.category_referee]}
								</div>
							</div>
						</div>
					</React.Fragment>
				))}
			</div>
		</>
	);
};

export default RefereeMatch;
