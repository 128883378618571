import React, { useContext, useEffect } from "react";
import TitleSection from "../components/titlecomponent/TitleSection";
import LanguageContext from "../context/LanguageContext";
import TitleSubSection from "../components/titlecomponent/TitleSubSection";
import { VERSION_APP } from "../const/Const";
import useRequest from "../hook/useRequest";
import GlobalContext from "../context/GlobalContext";
import RequestContext from "../context/RequestContext";
import ItemDownload from "../components/download/ItemDownload";
import IconDownload from "../components/imagenesReact/IconDownload";
import SkeletonDown from "../components/download/skeletonDown/SkeletonDown";
import { LanguageButton } from "../components/language/LanguageButton";

const Download = () => {
	const { textTranslate, handleLanguageButton } = useContext(LanguageContext);
	const { versionApp } = useContext(GlobalContext);
	const { loading } = useContext(RequestContext);

	const { getVersion } = useRequest();

	useEffect(() => {
		getVersion(VERSION_APP, "GET", null, null);
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const betaApp = versionApp?.Version?.filter(
		(test) => test.test === 1 // eslint-disable-next-line
	);

	const fullApp = versionApp?.Version?.filter(
		(test) => test.test === 0 // eslint-disable-next-line
	);

	const { Title, SubTitle, Table, NotExits } = textTranslate.Download;
	const testsize = "t4";

	return (
		<>
			<div className="secondHeader">
				<div className="contentLogin"></div>
				<div className="contentBtnLanguage">
					<LanguageButton
						handleLanguageButton={handleLanguageButton}
					/>
				</div>
			</div>
			<TitleSection title={Title} />
			<div>
				<TitleSubSection title={SubTitle} underline="true" />

				<div className="contentDownloadVersion p32-s">
					<div className="contentDonwloadSection">
						<div className="contentTitleDownloadSection">
							<TitleSubSection
								title="QuadStatistic app beta"
								sizetext={testsize}
							/>
						</div>
						<div className="w100-s">
							<table className="w100-s">
								<thead className="w100-s">
									<tr className="headerItemDownloadVersion p8-s w100-s">
										<th className="namehead pr16-s">
											{Table.Name}
										</th>
										<th className="nameDownloadVersion pr16-s">
											{Table.Description}
										</th>
										<th className="nameDownloadVersion pr16-s">
											{Table.Version}
										</th>
										<th className="nameDownloadVersion pr16-s">
											{Table.Release}
										</th>
										<th className="nameDownloadVersion pr16-s">
											{Table.Download}
										</th>
									</tr>
								</thead>
								<tbody className="w100-s">
									{betaApp?.length === 0 && loading && (
										<tr className="itemDownloadVersion skeletonData w100-s p16-s">
											<SkeletonDown />
										</tr>
									)}
									{betaApp?.length === 0 && !loading ? (
										<tr className="itemDownloadVersion noDataVersion w100-s p16-s">
											{NotExits}
										</tr>
									) : (
										betaApp?.map((item) => (
											<tr
												className="itemDownloadVersion p16-s"
												key={item.id}
											>
												<ItemDownload
													itemrows={item}
													testFolder="test"
												/>
											</tr>
										))
									)}
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<div className="contentDownloadVersion p32-s">
					<div className="contentDonwloadSection">
						<div className="contentTitleDownloadSection">
							<TitleSubSection
								title="QuadStatistic app"
								sizetext={testsize}
							/>
						</div>
						<div className="w100-s">
							<table className="w100-s">
								<thead>
									<tr className="headerItemDownloadVersion p8-s w100-s">
										<th className="namehead pr16-s">
											{Table.Name}
										</th>
										<th className="nameDownloadVersion pr16-s">
											{Table.Description}
										</th>
										<th className="nameDownloadVersion pr16-s">
											{Table.Version}
										</th>
										<th className="nameDownloadVersion pr16-s">
											{Table.Release}
										</th>
										<th className="nameDownloadVersion pr16-s">
											{Table.Download}
										</th>
									</tr>
								</thead>
								<tbody>
									{fullApp?.length === 0 && loading && (
										<tr className="itemDownloadVersion p16-s">
											<SkeletonDown />
										</tr>
									)}
									{fullApp?.length === 0 && !loading ? (
										<tr className="itemDownloadVersion noDataVersion p16-s">
											{NotExits}
										</tr>
									) : (
										fullApp?.map((item) => (
											<tr
												className="itemDownloadVersion p16-s"
												key={item.id}
											>
												<ItemDownload
													itemrows={item}
													testFolder="app"
												/>
											</tr>
										))
									)}
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<div className="contentDownloadVersion p32-s">
					<div className="contentDonwloadSection">
						<div className="contentTitleDownloadSection">
							<TitleSubSection
								title="Documentación / manuales"
								sizetext={testsize}
							/>
						</div>
						<div className="w100-s">
							<table className="w100-s">
								<thead>
									<tr className="headerItemDownloadVersion p8-s">
										<th className="namehead pr16-s">
											{Table.Name}
										</th>

										<th className="nameDownloadVersion pr16-s">
											{Table.Download}
										</th>
									</tr>
								</thead>
								<tbody>
									<tr className="itemDownloadVersion p8-s w100-s">
										<td className="nameDownloadVersion pr16-s w80-s w90-lg">
											Quadstatistics_general_manual_v_2_0_0.pdf
										</td>
										<td className="buttonDownload plr16-s">
											<div className="w24px-s center-cross">
												<a
													href="https://quadstatistic.com/download/manuales/Quadstatistics_general_manual_v_2_0_0.pdf"
													download
												>
													<IconDownload />
												</a>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Download;
