import React from "react";
import { Link, useParams } from "react-router-dom";
import { URL_IMG } from "../../../const/Const";

const ItemChampions = ({
	code,
	namechampionship,
	name,
	hadleClickItemChampions,
	nextOrderPage,
}) => {
	const { idOrganization, pageRequest, seasonsCode } = useParams();

	return (
		<>
			<li className="itemChampions">
				<div className="item-champion-container">
					<Link
						to={`/schedule/${nextOrderPage}/${pageRequest}/${idOrganization}/${seasonsCode}/${code}`}
						className="item-champion-link"
						onClick={hadleClickItemChampions}
					>
						<img
							src={`${URL_IMG}/web/logo/organizations/${name.toLowerCase()}.png`}
							alt="placeholder"
						/>
						<p className="name-championsship smaller">
							{namechampionship}
						</p>
						<h3 className="name-country">{name}</h3>
					</Link>
				</div>
			</li>
		</>
	);
};

export default ItemChampions;
