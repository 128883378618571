import React, { useContext } from "react";
import ReactDOM from "react-dom";
import "./css/modalauth.scss";
import "./css/selectauth.scss";
import DashContext from "../../../context/DashContent";
// Importa los componentes y estilos necesarios

const ModalAuth = ({ children, elementById, modalShow, setModalShow }) => {
	const { setErrorSelect } = useContext(DashContext);
	return ReactDOM.createPortal(
		<article className={`modal ${modalShow && "is-open"}`}>
			<div
				className={`contentModalAuth ${
					modalShow ? "zoomIn" : "zoomOut"
				}`}
			>
				<div className="modalAuth">
					<span
						className="close"
						onClick={() => {
							setModalShow(false);
							setErrorSelect(false);
						}}
					>
						&times;
					</span>
					{children}
				</div>
			</div>
		</article>,
		document.getElementById(elementById)
	);
};

export default ModalAuth;
