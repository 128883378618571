import React, { useEffect, useRef, useState } from 'react'
import './styles/carousel.css'

const Carousel = ({ children, show, infiniteLoop, HomeOrganizations }) => {
	// const { children, show, infiniteLoop } = props

	const [currentIndex, setCurrentIndex] = useState(infiniteLoop ? show : 0);
	const [length, setLength] = useState(children.length);

	const [isRepeating, setIsRepeating] = useState(
		infiniteLoop && children.length > show
	);
	const [transitionEnabled, setTransitionEnabled] = useState(true);

	const [touchPosition, setTouchPosition] = useState(null);

	// Set the length to match current children from props
	useEffect(() => {
		setLength(children.length);
		setIsRepeating(infiniteLoop && children.length > show);
	}, [children, infiniteLoop, show]);

	useEffect(() => {
		if (isRepeating) {
			if (currentIndex === show || currentIndex === length) {
				setTransitionEnabled(true);
			}
		}
	}, [currentIndex, isRepeating, show, length]);

	const next = () => {
		if (isRepeating || currentIndex < length - show) {
			setCurrentIndex((prevState) => prevState + 2);
		}
	};

	const prev = () => {
		if (isRepeating || currentIndex > 0) {
			setCurrentIndex((prevState) => prevState - 2);
		}
	};

	const handleTouchStart = (e) => {
		const touchDown = e.touches[0].clientX;
		setTouchPosition(touchDown);
	};

	const handleTouchMove = (e) => {
		const touchDown = touchPosition;

		if (touchDown === null) {
			return;
		}

		const currentTouch = e.touches[0].clientX;
		const diff = touchDown - currentTouch;

		if (diff > 5) {
			next();
		}

		if (diff < -5) {
			prev();
		}

		setTouchPosition(null);
	};

	const handleTransitionEnd = () => {
		if (isRepeating) {
			if (currentIndex === 0) {
				setTransitionEnabled(false);
				setCurrentIndex(length);
			} else if (currentIndex === length + show) {
				setTransitionEnabled(false);
				setCurrentIndex(show);
			}
		}
	};

	const renderExtraPrev = () => {
		let output = [];
		for (let index = 0; index < show; index++) {
			output.push(children[length - 1 - index]);
		}
		output.reverse();
		return output;
	};

	const renderExtraNext = () => {
		let output = [];
		for (let index = 0; index < show; index++) {
			output.push(children[index]);
		}
		return output;
	};

	let ElementRef = useRef();
	// useEffect(() => {
	//     let Elementcount = ElementRef.current.childNodes.length
	// })

	// if (touchPosition) alert("cambio de estado")
	return (
		<div className="carousel-container">
			<h2 className="carousel-title title wth-100-from-s text-center-from-s fw100-s">
				{HomeOrganizations.Title}
			</h2>
			<div className="carousel-wrapper">
				{/* You can alwas change the content of the button to other things */}
				{(isRepeating || currentIndex > 0) && (
					<button onClick={prev} className="left-arrow">
						&lt;
					</button>
				)}
				<div
					className="carousel-content-wrapper"
					onTouchStart={handleTouchStart}
					onTouchMove={handleTouchMove}
				>
					<ul
						className={`carousel-content show-${show}`}
						style={{
							transform: `translateX(-${
								currentIndex * (100 / show)
							}%)`,
							transition: !transitionEnabled ? "none" : undefined,
						}}
						onTransitionEnd={() => handleTransitionEnd()}
						ref={ElementRef}
					>
						{length > show && isRepeating && renderExtraPrev()}
						{children}
						{length > show && isRepeating && renderExtraNext()}
					</ul>
				</div>
				{/* You can alwas change the content of the button to other things */}
				{(isRepeating || currentIndex < length - show) && (
					<button onClick={next} className="right-arrow">
						&gt;
					</button>
				)}
			</div>
		</div>
	);
};

export default Carousel
