import React from "react";
import { URL_IMG } from "../../const/Const";

const ImgFlag = ({ language, country }) => {
	return (
		<>
			<img
				src={`${URL_IMG}/web/country/${country}.png`}
				alt=""
				className="flagLanguage"
			/>
			<p className="textLanguage">{language}</p>
		</>
	);
};

export default ImgFlag;
