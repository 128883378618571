const contrast = (rgb) => {
	let C = [rgb.r / 255, rgb.g / 255, rgb.b / 255];

	for (let i = 0; i < C.length; ++i) {
		if (C[i] <= 0.03928) {
			C[i] = C[i] / 12.92;
		} else {
			C[i] = Math.pow((C[i] + 0.055) / 1.055, 2.4);
		}
	}
	let L = 0.2126 * C[0] + 0.7152 * C[1] + 0.0722 * C[2];

	if (L > 0.179) {
		return "black";
	} else {
		return "white";
	}
};

const IsLigth = (colour) => {
	let digit = colour.slice(3);

    // Convirtiendo String Hexadecimal a RGB
	let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(digit);
	let rgb = result
		? {
				r: parseInt(result[1], 16),
				g: parseInt(result[2], 16),
				b: parseInt(result[3], 16),
		  }
		: null;
	
	//Formula que determinara el color blanco o negro de las letras
	let colorText = contrast(rgb);

	return colorText;
};

const IsWhite = (colour) => {
	let colourbase = "FFFFF0";
	if (parseInt("0x" + colour, 16) > parseInt("0x" + colourbase, 16)) {
		return "F5F5F5";
	} else {
		return colour;
	}
};

export { IsWhite, IsLigth };



// https://codepen.io/thunder01/pen/jQgKMx