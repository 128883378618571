import * as React from "react";
const Users = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		xmlSpace="preserve"
		id="Capa_1"
		x={0}
		y={0}
		style={{
			enableBackground: "new 0 0 135 135",
		}}
		viewBox="0 0 135 135"
		{...props}
	>
		<style>{".st3{fill:#ee751b}"}</style>
		<circle
			cx={67.5}
			cy={67.5}
			r={57.5}
			style={{
				fill: "none",
				stroke: "#e0e0e0",
				strokeWidth: 10,
				strokeMiterlimit: 10,
			}}
		/>
		<circle
			cx={67.5}
			cy={67.5}
			r={53.5}
			style={{
				fill: "#fff",
				stroke: "#a5a4a4",
				strokeMiterlimit: 10,
			}}
		/>
		<path
			d="M46.63 65.28c3.6 0 6.51-2.91 6.51-6.51 0-3.6-2.91-6.51-6.51-6.51s-6.51 2.91-6.51 6.51c0 3.59 2.92 6.5 6.51 6.51zm7.84 5.15a6.519 6.519 0 0 0-4.59-1.89h-6.51c-3.59 0-6.51 2.92-6.51 6.51v3.26c0 1.8 1.46 3.26 3.26 3.26h6.7c.62-4.73 3.47-8.87 7.65-11.14z"
			style={{
				fill: "#dd2d2e",
			}}
		/>
		<path
			d="M79.3 88.32a8.68 8.68 0 0 1-.68-.39c-.81.61-1.78.95-2.8.99a4.106 4.106 0 0 1-2.94-1.28 18.587 18.587 0 0 1-4.06-7.08c-1.09-3.51 2.53-5.06 2.62-5.12v-.79l-.83-.47c-.38-.22-.71-.49-1-.82-.33 0-.68.06-.99.06-2.42-.01-4.8-.57-6.97-1.63h-.85c-6.47 0-11.72 5.24-11.73 11.72V86.44c0 2.69 2.18 4.88 4.87 4.89H79.93c-.39-.61-.61-1.31-.63-2.03v-.98zM68.25 69.53c.19 0 .37-.05.57-.06.84-2.23 2.09-4.27 3.7-6.03.76-.82 1.83-1.28 2.94-1.28.7 0 1.39.19 1.99.54l.81.47.24-.14c2.68-5.67.26-12.45-5.41-15.13s-12.45-.26-15.13 5.41c-2.68 5.67-.26 12.45 5.41 15.13 1.52.72 3.19 1.1 4.88 1.09z"
			className="st3"
		/>
		<path
			d="m100.88 78.8-2.63-1.54c.26-1.43.26-2.9 0-4.33l2.62-1.52c.3-.17.44-.53.34-.87-.68-2.17-1.84-4.16-3.38-5.83a.718.718 0 0 0-.91-.14l-2.62 1.52c-1.11-.95-2.38-1.68-3.76-2.17v-3.03a.73.73 0 0 0-.58-.72c-2.23-.5-4.54-.5-6.77 0a.72.72 0 0 0-.58.72v3.03c-1.37.49-2.65 1.23-3.76 2.17l-2.62-1.52a.746.746 0 0 0-.92.14c-1.55 1.67-2.7 3.66-3.38 5.83-.1.33.04.69.34.87l2.63 1.52c-.26 1.43-.26 2.9 0 4.33l-2.63 1.52a.75.75 0 0 0-.34.87c.68 2.17 1.84 4.17 3.38 5.84.24.25.62.31.92.14l2.62-1.52c1.11.95 2.38 1.68 3.76 2.17v3.03c0 .35.24.65.58.72 2.23.51 4.54.51 6.77 0a.73.73 0 0 0 .58-.72v-3.03c1.37-.49 2.65-1.22 3.76-2.17l2.63 1.52c.3.17.67.13.91-.12 1.55-1.67 2.7-3.67 3.38-5.84.1-.34-.04-.69-.34-.87zm-14.01 1.33c-2.72 0-4.93-2.21-4.93-4.93 0-2.72 2.21-4.93 4.93-4.93 2.72 0 4.93 2.21 4.93 4.93 0 2.72-2.21 4.93-4.93 4.93z"
			style={{
				fill: "#21a8e0",
			}}
		/>
	</svg>
);
export default Users;
