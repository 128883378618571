import { useContext } from "react";
import EyeHide from "../../components/img/EyeHide";
import EyeShow from "../../components/img/EyeShow";
import useUser from "../../hook/useUser";
import TitleSubSection from "../../components/titlecomponent/TitleSubSection";
import RequestContext from "../../context/RequestContext";
import LogoName from "../../components/logoname/LogoName";
import LoadingModal from "../component/authorizationcomponent/LoadingModal";
import ModalLoading from "../../components/modals/modalloading/ModalLoading";
import LoginContext from "../../context/LoginContext";

const ForgotPassword = () => {
	const { email, setEmail, emailError, setEmailError } =
		useContext(LoginContext);

	const { forgotPasswordUser } = useUser();
	const { loading, setLoading, message, errorHttp } =
		useContext(RequestContext);
	const testsize = "t4";

	const handleEmailChange = (event) => {
		const newEmail = event.target.value;
		setEmail(newEmail);
		setEmailError("");
	};

	const handleSubmit = (event) => {
		event.preventDefault();

		const isValidEmail = (email) => {
			const emailRegex =
				/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
			return emailRegex.test(email);
		};

		let msgEmailError = "";

		if (email.length === 0 || !isValidEmail(email)) {
			msgEmailError =
				"Ingresa una dirección de correo válido.";
		}

		setEmailError(msgEmailError);

		if (!msgEmailError) {
			forgotPasswordUser({ email });
			setEmailError("");
		}
	};

	return (
		<div className="mainForm">
			{loading ||
				(errorHttp && (
					<ModalLoading
						elementById="modal"
						loading={loading}
						setLoading={setLoading}
					>
						<LoadingModal message={message} titleModal="Cargando" />
					</ModalLoading>
				))}

			<div className="containerForm df-s jcc-s aic-s">
				<div className="cardForm mb3-s">
					<div className="card-body">
						<LogoName />
						<div className="contentTitle mt16-s">
							<TitleSubSection
								title="Recordar contraseña"
								sizetext={testsize}
							/>
						</div>
						<form onSubmit={handleSubmit}>
							<div className="content-input mb8-s dg-s fww-s">
								<label className="form-label">Email</label>
								<input
									type="text"
									className={
										emailError
											? "w100-s border-danger"
											: "w100-s"
									}
									title="Ingresa una dirección de correo válido"
									placeholder="Cuenta de correo"
									onChange={handleEmailChange}
									value={email}
									autoComplete="email"
								/>
							</div>
							<div className="content-danger">
								{emailError && (
									<p className="text-danger">{emailError}</p>
								)}
							</div>
							<div className="df-s jcc-s m16-s ">
								<button
									className="btn btn-outline-primary text-center shadow-none mb-3 button gray-button-color small"
									type="submit"
								>
									Cambiar contraseña
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ForgotPassword;
