import React from "react";
import { useParams } from "react-router-dom";
import { URL_IMG } from "../../../../../const/Const";
import RoundImage from "../../../../imagenesReact/RoundImage";

const StatisticMainPlayer = ({
	players,
	colour,
	ligthcolour,
	textTranslate,
	teamshort,
}) => {
	const { idOrganization } = useParams();

	const stylecss = {
		color: {
			backgroundColor: "#" + colour,
		},
	};
	const fontColor = `color-text-${ligthcolour}`;

	return (
		<>
			<div className="playerStatistics">
				{players
					?.sort((a, b) =>
						parseInt(a.numberplayer) > parseInt(b.numberplayer)
							? 1
							: -1
					)
					.map((item) => (
						<ul className="player" key={item.playercode}>
							<li className="player__item">
								<div className="playercard">
									<div
										className={`playercard__number ${fontColor}`}
										style={stylecss.color}
									>
										<p>{item.numberplayer}</p>
									</div>
									<div className="playercard__img">
										<RoundImage
											url={`${URL_IMG}/organizations/${idOrganization}/players/${teamshort}/${item.imageUrl}`}
											altImgText="Player image "
										/>
									</div>
									<div className="playercard__name pl16-s">
										{item.nameplayer}
									</div>
								</div>
							</li>
							<li className="player__item">
								<div className="thead_mobile">
									{textTranslate.Players.HeadTable.Tries}
								</div>
								<p>{item.try}</p>
							</li>
							<li className="player__item">
								<div className="thead_mobile">
									{textTranslate.Players.HeadTable.Retrive}
								</div>
								<p>{item.recover}</p>
							</li>
							<li className="player__item">
								<div className="thead_mobile">
									{textTranslate.Players.HeadTable.Excluded}
								</div>
								<p>{item.excluded}</p>
							</li>
							<li className="player__item">
								<div className="thead_mobile">
									{textTranslate.Players.HeadTable.Steals}
								</div>
								<p>{item.steals}</p>
							</li>
							<li className="player__item">
								<div className="thead_mobile">
									{textTranslate.Players.HeadTable.Assistance}
								</div>
								<p>{item.assists}</p>
							</li>
							<li className="player__item">
								<div className="thead_mobile">
									{textTranslate.Players.HeadTable.Lost}
								</div>
								<p>{item.turnovers}</p>
							</li>
							<li className="player__item">
								<div className="thead_mobile">
									{textTranslate.Players.HeadTable.LvCourt}
								</div>
								<p>{item.leavingcourt}</p>
							</li>
							<li className="player__item">
								<div className="thead_mobile">
									{textTranslate.Players.HeadTable.Class}
								</div>
								<p>{item.sportclass}</p>
							</li>
						</ul>
					))}
			</div>
		</>
	);
};

export default StatisticMainPlayer;
