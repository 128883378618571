import React from "react";
import { useParams } from "react-router-dom";
import { LOGO_NOT_FOUND, URL_IMG } from "../../../const/Const";

const ScoreCard = ({ team, colourteam, logoteam, shortname }) => {
	const stylecss = {
		color: {
			backgroundColor: "#" + colourteam,
		},
	};

	const { idOrganization } = useParams();

	return (
		<div className="card">
			<div className="card__color w12px-s" style={stylecss.color}></div>
			<div className="card__logo">
				<img
					className="w32px-s-i w65px-ipad-i w90px-lg-i"
					src={
						logoteam === null || ""
							? LOGO_NOT_FOUND
							: `${URL_IMG}/organizations/${idOrganization}/teams/${shortname}/${logoteam}`
					}
					alt="Logo equipo local"
				/>
			</div>
			<div className="card__vertical-line is-none-to-lg"></div>
			<div className="card__name normal">{team}</div>
		</div>
	);
};

export default ScoreCard;
