import React from "react";
import { Link } from "react-router-dom";
import "./styles/itemcarousel.css";
import { URL_IMG } from "../../const/Const";


const ItemCarousel = ({
	id,
	nameorganizations,
	namecountry,
	pagerequest,
	order,
	// HomeOrganizations,
}) => {
	return (
		<>
			<li>
				<div className="item-carousel-container">
					<Link
						to={`/seasons/${order}/${pagerequest}/${id}`}
						className="item-carousel-link"
					>
						<img
							src={`${URL_IMG}/web/country/${namecountry}.png`}
							alt="placeholder"
						/>
						<h3 className="name-country">
							{/* {HomeOrganizations[namecountry]} */}
							{namecountry}
						</h3>
						<p className="name-championsship smaller">
							{nameorganizations.toUpperCase()}
						</p>
					</Link>
				</div>
			</li>
		</>
	);
};

export default ItemCarousel;
