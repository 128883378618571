import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import TabsPanel from "../components/tabscomponent/TabsPanel";
import TabsSwitcher from "../components/tabscomponent/TabsSwitcher";
import useRequest from "../hook/useRequest";
import GlobalContext from "../context/GlobalContext";
import { WEB_ALL } from "../const/Const";
import { ObjectToUrlParam } from "../components/utilities/ObjectToUrlParam";
import useUpdateState from "../hook/useUpdateState";
import useCss from "../hook/useGetCss";
import Breadcrumb from "../components/breadcrumb/Breadcrumb";
import useGetPathBreadcrumbs from "../components/breadcrumb/hook/useGetPathBreadcrumbs";
import SkeletonMatchList from "../components/main/scheduleList/skeleton/SkeletonMatchList";
import LanguageContext from "../context/LanguageContext";
import { LanguageButton } from "../components/language/LanguageButton";
import ScheduleGroup from "../components/main/scheduleList/ScheduleGroup";
import ScheduleStandings from "../components/main/scheduleList/ScheduleStandings";
import ScheduleStandout from "../components/main/scheduleList/ScheduleStandout";
import ScheduleLeague from "../components/main/scheduleList/ScheduleLeague";
import SchedulePlayoff from "../components/main/scheduleList/SchedulePlayoff";
import TitleSection from "../components/titlecomponent/TitleSection";

const Schedule = () => {
	const {
		orderPage,
		idOrganization,
		pageRequest,
		seasonsCode,
		championsCode,
	} = useParams();

	const { getCssOrg } = useCss();

	useEffect(() => {
		getCssOrg(pageRequest);
	}, [getCssOrg, pageRequest]);

	const { getAllData } = useRequest();

	const { delStateStorage } = useUpdateState();

	const { tabSchedule, setTabSchedule, data, setData } =
		useContext(GlobalContext);

	const params = useParams();

	const { constructorCrumbs } = useGetPathBreadcrumbs();

	const { textTranslate, handleLanguageButton } = useContext(LanguageContext);

	const { Modal, State, TabSchedule, Result, Playoff, Standings, Standout } =
		textTranslate?.Schedule;

	const dataBodyOptions = useMemo(() => {
		const items = {
			organization_id: idOrganization,
			page_request: pageRequest,
			season_code: seasonsCode,
			championship_code: championsCode,
		};
		return items;
	}, [idOrganization, pageRequest, seasonsCode, championsCode]);

	useEffect(() => {
		setData(null);
		delStateStorage(["data"]);

		getAllData(
			WEB_ALL + "?" + ObjectToUrlParam(dataBodyOptions),
			"GET",
			null,
			null
		);
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const breadcrumbsList = useMemo(() => {
		return (
			<Breadcrumb
				dataPath={constructorCrumbs(
					params,
					parseInt(orderPage),
					parseInt(pageRequest)
				)}
			/>
		);
	}, [constructorCrumbs, orderPage, params, pageRequest]);

	const handleClickTabList = useCallback(
		(e) => {
			e.preventDefault();
			setTabSchedule(e.currentTarget.id);
			window.sessionStorage.setItem(
				"tabschedule",
				JSON.stringify(e.currentTarget.id)
			);
		},
		[setTabSchedule]
	);

	const filterchampionship = useCallback(() => {
		if (data?.Championships) {
			return data.Championships.find(
				(item) => item.code === championsCode
			);
		}
	}, [data, championsCode]);

	const filterType = useCallback(
		(type) => {
			if (data?.MatchsListChampionship) {
				return data.MatchsListChampionship.filter((item) => {
					return type !== null
						? item.matchtype === type
						: item.matchtype !== 1 && item.matchtype !== 3;
				});
			}
		},
		[data]
	);

	const nametabs = useCallback(
		(type) => {
			// eslint-disable-next-line default-case
			switch (type) {
				case 1:
					return [
						{ name: TabSchedule.Tab1, tabposition: 1 },
						{ name: TabSchedule.Tab3, tabposition: 2 },
						{ name: TabSchedule.Tab4, tabposition: 3 },
					];

				case 2:
					return [
						{ name: TabSchedule.Tab1, tabposition: 1 },
						{ name: TabSchedule.Tab2, tabposition: 2 },
						{ name: TabSchedule.Tab3, tabposition: 3 },
						{ name: TabSchedule.Tab4, tabposition: 4 },
					];

				case 3:
					return [
						{ name: TabSchedule.Tab2, tabposition: 1 },
						{ name: TabSchedule.Tab4, tabposition: 2 },
					];
			}
		},
		[TabSchedule.Tab1, TabSchedule.Tab2, TabSchedule.Tab3, TabSchedule.Tab4]
	);

	return (
		<>
			{" "}
			<div className="secondHeader">
				<div className="contentLogin"></div>
				<div className="contentBtnLanguage">
					<LanguageButton
						handleLanguageButton={handleLanguageButton}
					/>
				</div>
			</div>
			<div className="main acs-s">
				{breadcrumbsList}
				{!data?.MatchsListChampionship && <SkeletonMatchList />}
				{data?.MatchsListChampionship &&
					data?.MatchsListChampionship.lentgh !== 0 &&
					data?.ChampionsLeagueRanking.original?.Ranking !== 0 &&
					textTranslate?.Schedule && (
						<TabsPanel>
							<TabsSwitcher
								nametabs={nametabs(
									filterchampionship()?.typechampionship
								)}
								handleClickTab={handleClickTabList}
								active={tabSchedule}
								classparent="df-s h50px-s jcc-s"
								classtext="tab-text bdright"
								classitem="df-s tab-item jcc-s acc-s fww-s bgnone"
								classitemactive="df-s tab-item jcc-s acc-s fww-s tab-item-active bgnone"
							/>
							{nametabs(
								filterchampionship()?.typechampionship
							)?.map((item) => (
								<React.Fragment key={item.name}>
									{item.name === TabSchedule.Tab1 && (
										<div
											className={
												tabSchedule ===
												item.tabposition.toString()
													? "w100-s"
													: "is-none-from-s"
											}
										>
											{filterchampionship()
												.typechampionship === 1 ? (
												<ScheduleLeague
													matchList={filterType(1)}
													textTranslate={Result}
													nextOrderPage={4}
													state={State}
													modal={Modal}
													setdata={setData}
													delstatestorage={
														delStateStorage
													}
												/>
											) : (
												<ScheduleGroup
													matchList={filterType(3)}
													textTranslate={Result}
													nextOrderPage={4}
													state={State}
													modal={Modal}
													setdata={setData}
													delstatestorage={
														delStateStorage
													}
												/>
											)}
										</div>
									)}
									{item.name === TabSchedule.Tab2 && (
										<div
											className={
												tabSchedule ===
												item.tabposition.toString()
													? "w100-s"
													: "is-none-from-s"
											}
										>
											<TitleSection
												title={Playoff?.Title}
												border={false}
											/>
											<SchedulePlayoff
												matchList={filterType(null)}
												textTranslate={Playoff}
												nextOrderPage={4}
												state={State}
												modal={Modal}
												setdata={setData}
												delstatestorage={
													delStateStorage
												}
											/>
										</div>
									)}
									{item.name === TabSchedule.Tab3 && (
										<div
											className={
												tabSchedule ===
												item.tabposition.toString()
													? "w100-s"
													: "is-none-from-s"
											}
										>
											<ScheduleStandings
												matchList={filterType(3)}
												title={Standings.Title}
												selectChampionsData={filterchampionship()}
												ranking={
													data?.ChampionsLeagueRanking
														.original?.Ranking
												}
												textTranslate={Standings}
											/>
										</div>
									)}
									{item.name === TabSchedule.Tab4 && (
										<div
											className={
												tabSchedule ===
												item.tabposition.toString()
													? "w100-s"
													: "is-none-from-s"
											}
										>
											{data && (
												<>
													<ScheduleStandout
														tries={data?.Tries}
														recover={data?.Recovers}
														assist={data?.Assists}
														textTranslate={Standout}
													/>
												</>
											)}
										</div>
									)}
								</React.Fragment>
							))}
						</TabsPanel>
					)}
			</div>
		</>
	);
};

export default Schedule;
