import { useCallback, useContext } from "react";
import LoginContext from "../context/LoginContext";
import doFetch from "../service/doFetch";
import { URL_BASIC_SERVER as ENDPOINT } from "../const/Const";
import RequestContext from "../context/RequestContext";
import DashContext from "../context/DashContent";

const useUser = () => {
	const {
		passwordShown,
		setPasswordShown,
		c_PasswordShown,
		setC_PasswordShown,
		setUserToken,
		setUserData,
		isAuth,
		userToken,
		setEmail,
		setPassword,
	} = useContext(LoginContext);
	const { setAllAuthorizeMatch, setAllUserEdit } = useContext(DashContext);

	const { setLoading, setMessage, setCode } = useContext(RequestContext);

	const togglePasswordVisiblity = () => {
		setPasswordShown(!passwordShown);
	};
	const toggleC_PasswordVisiblity = () => {
		setC_PasswordShown(!c_PasswordShown);
	};

	const login = useCallback(
		({ email, password }) => {
			setLoading(true);
			doFetch(`${ENDPOINT}user/login`, "POST", {
				email,
				password,
			})
				.then((response) => {
					const { Token, Code, User, Message } = response;
					if (Code === 202 /*&& User.role_id === 1*/) {
						setUserData(User);
						window.sessionStorage.setItem(
							"usrd",
							JSON.stringify(User)
						);
						setUserToken(Token);
						window.sessionStorage.setItem(
							"usrt",
							JSON.stringify(Token)
						);
					} else if (Code === 401) {
						setMessage(Message);
						setCode(Code);
					} /*else if (Code === 202 && User.role_id !== 1) {
						setLoading(false);
						setMessage("Acceso denegado");
						setCode(Code);
						setUserData(null);
						setUserToken(null);
					}*/
					setLoading(false);
				})
				.catch((err) => {
					setLoading(false);
				});
		},
		[setUserData, setUserToken, setCode, setLoading, setMessage]
	);

	const updateUser = (body, id) => {
		doFetch(
			`${ENDPOINT}user/restnetwork/update/${id}`,
			"PUT",
			body,
			userToken
		)
			.then((response) => {
				const { Message } = response;
				const { Code } = response;
				if (Code === "200") {
					setMessage(Message);
				}
			})
			.catch((err) => {});
	};

	const resetPasswordUser = useCallback(
		({ id, password, c_password, Token }) => {
			setLoading(true);
			doFetch(
				`${ENDPOINT}user/restnetwork/reset/password`,
				"PUT",
				{ id, password, c_password },
				Token
			)
				.then((response) => {
					const { Message } = response;
					const { Code } = response;
					if (Code === "200") {
						setMessage(Message);
					}
					setLoading(false);
				})
				.catch((err) => {
					setLoading(false);
				});
		},
		[setLoading, setMessage]
	);

	const forgotPasswordUser = useCallback(
		({ email }) => {
			setLoading(true);
			doFetch(`${ENDPOINT}user/restnetwork/forgot/password`, "POST", {
				email,
			})
				.then((response) => {
					const { Message } = response;
					const { Code } = response;
					if (Code === "200") {
						setMessage(Message);
					}
					setLoading(false);
				})
				.catch((err) => {
					setLoading(false);
				});
		},
		[setLoading, setMessage]
	);

	const logout = () => {
		setLoading(true);
		doFetch(`${ENDPOINT}user/logout`, "POST", {}, userToken)
			.then((response) => {
				const { Code } = response;
				if (Code === "200") {
					sessionStorage.removeItem("usrt");
					sessionStorage.removeItem("usrd");
					setUserToken(null);
					setUserData(null);
					setEmail("");
					setPassword("");
					setAllAuthorizeMatch(null);
					setAllUserEdit(null);
				}
				setLoading(false);
			})
			.catch((err) => {
				sessionStorage.removeItem("usrt");
				sessionStorage.removeItem("usrd");
				setUserToken(null);
				setUserData(null);
				setEmail("");
				setPassword("");
				setAllAuthorizeMatch(null);
				setAllUserEdit(null);
				setLoading(false);
			});
	};

	return {
		isStateUser: isAuth,
		login,
		updateUser,
		resetPasswordUser,
		forgotPasswordUser,
		logout,
		togglePasswordVisiblity,
		toggleC_PasswordVisiblity,
	};
};

export default useUser;
