import React from "react";
import { IsLigth, IsWhite } from "../../utilities/WatchColor";
import StatisticsTeam from "./StatisticsTeam/StatisticsTeam";

const StatisticsAll = ({
	selectMatchData,
	StatisticsByPlayerMatchLocal,
	StatisticsByMatchTeamLocal,
	TechnicalTeamLocal,
	StatisticsByPlayerMatchVisitor,
	StatisticsByMatchTeamVisitor,
	TechnicalTeamVisitor,
	textTranslate,
}) => {
	return (
		<>
			<StatisticsTeam
				logo={selectMatchData.logoteam1}
				team={selectMatchData.nameteam1}
				colour={IsWhite(selectMatchData.colourteam1.slice(3))}
				state={selectMatchData.state}
				ligthcolourteam={IsLigth(selectMatchData.colourteam1)}
				StatisticsByPlayerMatch={StatisticsByPlayerMatchLocal}
				StatisticsByMatchTeam={StatisticsByMatchTeamLocal}
				TechnicalTeam={TechnicalTeamLocal}
				textTranslate={textTranslate}
				teamshort={selectMatchData.localshortname}
			/>
			<StatisticsTeam
				logo={selectMatchData.logoteam2}
				team={selectMatchData.nameteam2}
				colour={IsWhite(selectMatchData.colourteam2.slice(3))}
				state={selectMatchData.state}
				ligthcolourteam={IsLigth(selectMatchData.colourteam2)}
				StatisticsByPlayerMatch={StatisticsByPlayerMatchVisitor}
				StatisticsByMatchTeam={StatisticsByMatchTeamVisitor}
				TechnicalTeam={TechnicalTeamVisitor}
				textTranslate={textTranslate}
				teamshort={selectMatchData.visitorshortname}
			/>
		</>
	);
};

export default StatisticsAll;
