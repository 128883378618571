import * as React from "react";
const EmailIcon = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		xmlSpace="preserve"
		baseProfile="basic"
		viewBox="0 0 900 900"
		{...props}
	>
		<g fill="#31b6b1">
			<path d="M318.48 384.18c44.51 29.73 88.41 59.05 132.21 88.3 43.48-29.12 86.62-58 130.12-87.13 1.32 1.67 2.54 3.18 3.71 4.72 10.57 13.91 21.09 27.86 31.7 41.74 9.94 13 19.97 25.93 29.96 38.88 12.37 16.05 24.76 32.09 37.13 48.14 13.4 17.39 26.79 34.78 40.19 52.17 12.5 16.22 25 32.44 37.52 48.65 11.39 14.75 22.82 29.48 34.21 44.23 10.18 13.18 20.33 26.36 30.48 39.56.89 1.16 1.63 2.43 2.81 4.2H69.95c82.9-107.9 165.64-215.58 248.53-323.46z" />
			<path d="M97.54 193.5h705.33c.13.25.27.51.4.76-117.63 78.66-235.26 157.32-353.11 236.12-117.79-78.76-235.4-157.39-353-236.02.13-.28.25-.57.38-.86zM844.18 670.48c-78.5-102.02-156.28-203.1-234.37-304.58 78.11-52.35 156.06-104.6 234.37-157.09v461.67zM289.51 364.75C211.62 466.14 134.2 566.92 56.1 668.57V208.82c77.9 52.04 155.57 103.93 233.41 155.93z" />
		</g>
	</svg>
);
export default EmailIcon;
