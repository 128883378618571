import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Champions from "../pages/Champions";
import Schedule from "../pages/Schedule";
import Home from "../pages/Home";
import Match from "../pages/Match";
import NotFound from "../pages/NotFound";
import Wrapper from "../service/Wrapper";
import Seasons from "../pages/Seasons";
import Download from "../pages/Download";
import { LoginContextProvider } from "../context/LoginContext";
import AccessRoute from "./AccessRoute";
import LoginPage from "../backend/pages/LoginPage";
import PrivateRoute from "./PrivateRoute";
// import OrganizationPage from "../backend/pages/OrganizationPage";
// import ChampionShipsPage from "../backend/pages/ChampionShipsPage";
// import MatchsPage from "../backend/pages/MatchsPage";
import AuthorizationPage from "../backend/pages/AuthorizationPage";
import { DashContextProvider } from "../context/DashContent";
import DashBoardPage from "../backend/pages/DashboardPage";
// import TeamsPage from "../backend/pages/TeamsPage";
// import PlayersPage from "../backend/pages/PlayersPage";
// import RefereesPage from "../backend/pages/RefereesPage";
import UsersPage from "../backend/pages/UsersPage";
import ResetPassword from "../backend/pages/ResetPassword";
import ForgotPassword from "../backend/pages/ForgotPassword";
import ContactForm from "../pages/ContactForm";
// import SeasonsPage from "../backend/pages/SeasonsPage";
// import TechnicalsPage from "../backend/pages/TechnicalsPage";

const WebRoute = () => {
	let org = "1";

	return (
		<>
			<div id="modal"></div>
			<div id="modal-auth"></div>
			<BrowserRouter>
				<Wrapper>
					<Routes>
						<Route
							exact
							path="/"
							element={
								<LoginContextProvider>
									<Home
										org={org}
										pageRequest={org}
										orderPage={0}
									/>
								</LoginContextProvider>
							}
						/>
						<Route
							exact
							path="/seasons/:orderPage/:pageRequest/:idOrganization/"
							element={<Seasons />}
						/>
						<Route
							exact
							path="/champions/:orderPage/:pageRequest/:idOrganization/:seasonsCode/"
							element={<Champions />}
						/>
						<Route
							exact
							path="/schedule/:orderPage/:pageRequest/:idOrganization/:seasonsCode/:championsCode"
							element={<Schedule />}
						/>
						<Route
							exact
							path="/match/:orderPage/:pageRequest/:idOrganization/:seasonsCode/:championsCode/:matchCode"
							element={<Match />}
						/>{" "}
						<Route
							exact
							path="/contact"
							element={<ContactForm />}
						/>
						<Route
							exact
							path="/reset-password/"
							element={
								<LoginContextProvider>
									<ResetPassword />
								</LoginContextProvider>
							}
						/>
						<Route
							exact
							path="/forgot-password/"
							element={
								<LoginContextProvider>
									<ForgotPassword />
								</LoginContextProvider>
							}
						/>
						{/* <Route
							exact
							path="/reset-password/"
							element={
								<LoginContextProvider>
									<ResetPassword />
								</LoginContextProvider>
							}
						/> */}
						{/* <Route
							exact
							path="/forgot-password/"
							element={
								<LoginContextProvider>
									<ForgotPassword />
								</LoginContextProvider>
							}
						/> */}
						<Route exact path="*" element={<NotFound />} />
						<Route
							exact
							path="/login"
							element={
								<LoginContextProvider>
									<DashContextProvider>
										<AccessRoute>
											<LoginPage />
										</AccessRoute>
									</DashContextProvider>
								</LoginContextProvider>
							}
						/>
						<Route
							exact
							path="/dashboard"
							element={
								<LoginContextProvider>
									<DashContextProvider>
										<PrivateRoute>
											<DashBoardPage />
										</PrivateRoute>
									</DashContextProvider>
								</LoginContextProvider>
							}
						/>
						<Route
							exact
							path="/download/"
							element={
								<LoginContextProvider>
									<DashContextProvider>
										<PrivateRoute>
											<Download />{" "}
										</PrivateRoute>
									</DashContextProvider>
								</LoginContextProvider>
							}
						/>
						{/* <Route
							exact
							path="/authorizations"
							element={
								<LoginContextProvider>
									<DashContextProvider>
										<PrivateRoute>
											<AuthorizationPage />
										</PrivateRoute>
									</DashContextProvider>
								</LoginContextProvider>
							}
						/> */}
						{/* <Route
							exact
							path="/user"
							element={
								<LoginContextProvider>
									<DashContextProvider>
										<PrivateRoute>
											<UsersPage />
										</PrivateRoute>
									</DashContextProvider>
								</LoginContextProvider>
							}
						/> */}
						{/* <Route
							exact
							path="/organization"
							element={
								<LoginContextProvider>
									<PrivateRoute>
										<OrganizationPage />
									</PrivateRoute>
								</LoginContextProvider>
							}
						/> */}
						{/* <Route
							exact
							path="/championships"
							element={
								<LoginContextProvider>
									<PrivateRoute>
										<ChampionShipsPage />
									</PrivateRoute>
								</LoginContextProvider>
							}
						/> */}
						{/* <Route
							exact
							path="/matchs"
							element={
								<LoginContextProvider>
									<DashContextProvider>
										<PrivateRoute>
											<MatchsPage />
										</PrivateRoute>
									</DashContextProvider>
								</LoginContextProvider>
							}
						/> */}
						{/* <Route
							exact
							path="/teams"
							element={
								<LoginContextProvider>
									<PrivateRoute>
										<TeamsPage />
									</PrivateRoute>
								</LoginContextProvider>
							}
						/>
						<Route
							exact
							path="/players"
							element={
								<LoginContextProvider>
									<PrivateRoute>
										<PlayersPage />
									</PrivateRoute>
								</LoginContextProvider>
							}
						/>
						<Route
							exact
							path="/referees"
							element={
								<LoginContextProvider>
									<PrivateRoute>
										<RefereesPage />
									</PrivateRoute>
								</LoginContextProvider>
							}
						/>
						<Route
							exact
							path="/users"
							element={
								<LoginContextProvider>
									<PrivateRoute>
										<UsersPage />
									</PrivateRoute>
								</LoginContextProvider>
							}
						/>
						<Route
							exact
							path="/seasons"
							element={
								<LoginContextProvider>
									<PrivateRoute>
										<SeasonsPage />
									</PrivateRoute>
								</LoginContextProvider>
							}
						/>
						<Route
							exact
							path="/technicals"
							element={
								<LoginContextProvider>
									<PrivateRoute>
										<TechnicalsPage />
									</PrivateRoute>
								</LoginContextProvider>
							}
						/> */}
					</Routes>
				</Wrapper>
			</BrowserRouter>
		</>
	);
};

export default WebRoute;
