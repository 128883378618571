import React from "react";
import StateViewColor from "../../utilities/StateViewColor";

const MatchData = ({
	data,
	time,
	state,
	textStateTranslate,
	scoreteam1,
	scoreteam2,
	period,
	textPeriodTranslate,
}) => {
	return (
		<div className="match__data">
			<div className="data">
				<StateViewColor
					state={state}
					textStateTranslate={textStateTranslate}
					position="data"
				/>
				<div className="data__contenthour">
					<div
						className={state === "PREVIUS" ? "data__hour" : "dn-s"}
					>
						{data}
					</div>
				</div>
				<div className="data__contenthour">
					<div
						className={state === "PREVIUS" ? "data__hour" : "dn-s"}
					>
						{time}
					</div>
				</div>
				<div className="data__contenthour">
					<div
						className={
							state === "FINISH" || state === "LIVE"
								? "data__hour"
								: "dn-s"
						}
					>
						{scoreteam1 + " - " + scoreteam2}
					</div>
				</div>
				<div className={state === "LIVE" ? "data__hour" : "dn-s"}>
					{textPeriodTranslate + " " + period}
				</div>
				<div className={state === "FINISH" ? "data__hour" : "dn-s"}>
					{/* Campo vacio para ocupar altura del periodo en live */}
				</div>
			</div>
		</div>
	);
};

export default MatchData;
