import React from "react";
import { useParams } from "react-router-dom";
import { LOGO_NOT_FOUND, URL_IMG } from "../../../const/Const";

const LocalCard = ({
	aditionalClass,
	teams1,
	logo1,
	teamshort,
	shortvalue,
}) => {
	const { idOrganization } = useParams();

	let valueName;
	let valueShort;
	if (shortvalue) {
		valueName = "card__name is-none-to-ipad";
		valueShort = "card__name card__name--right is-none-from-ipad";
	} else {
		valueName = "card__name";
		valueShort = "card__name card__name--right is-none-from-s";
	}

	return (
		<div className={"card " + aditionalClass}>
			<div className="card__logo">
				<img
					className="w32px-s-i"
					// src={props.logo1}
					src={
						(logo1 === null || "")
							? LOGO_NOT_FOUND
							: `${URL_IMG}/organizations/${idOrganization}/teams/${teamshort}/${logo1}`
					}
					alt="Logo equipo local"
				/>
			</div>
			<p className={valueName}>
				{teams1 === null || "" ? "LOCAL" : teams1}
			</p>
			<p className={valueShort}>
				{teamshort === null || "" ? "LOCAL" : teamshort}
			</p>
		</div>
	);
};

export default LocalCard;
