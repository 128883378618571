import React from "react";
import EmailIcon from "../../../components/img/EmailIcon";

const TokenExpireModal = ({ HandleClosed, HandleSendEmail,message }) => {
	return (
		<>
			<div className="frameView">
				<div className="viewModal">
					<h2 className="titleModal">{message.titleresponse}</h2>
					<div className="contentShowResponseIcon">
						<p>{message.msnresponse}</p>
					</div>
					<div className="contentButton">
						<div
							onClick={() => HandleSendEmail()}
							className="buttonIcon"
						>
							<div className="textIcon">Solicitar</div>
							<div title="Mandar email" className="emailIcon">
								<EmailIcon />
							</div>
						</div>
						<div onClick={HandleClosed} className="buttonIcon">
							<div className="textIcon">Cerrar</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default TokenExpireModal;
