import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import GlobalContext from "../../context/GlobalContext";
import "./style/style.scss";

const Breadcrumb = ({ dataPath }) => {
	let clearCrumbs;

	const {
		previousURLRef,
		setPreviousURLRef,
		currentPageRef,
		setCurrentPageRef,
	} = useContext(GlobalContext);

	const [pathBreadcrumbs, setPathBreadcrumbs] = useState(
		sessionStorage.getItem("pathBreadcrumbs") !== null
			? JSON.parse(sessionStorage.getItem("pathBreadcrumbs"))
			: {}
	);

	const pathLocation = useLocation().pathname;

	//agrega los datos de crumbs de la pagina en el que esta insertado el componente breadCrumb
	useEffect(() => {
		if (pathBreadcrumbs) {
			setPathBreadcrumbs(dataPath);
			window.sessionStorage.setItem(
				"pathBreadcrumbs",
				JSON.stringify(dataPath)
			);
		}
	}, [dataPath]); // eslint-disable-line react-hooks/exhaustive-deps

	//Logica de eliminar el ultimo objeto al pulsar hacia atras en el navegador
	useEffect(() => {
		setCurrentPageRef(pathBreadcrumbs.length);
		window.onpopstate = () => {
			if (currentPageRef > previousURLRef) {
				setPreviousURLRef(previousURLRef - 1);
				window.sessionStorage.setItem(
					"previousURLRef",
					JSON.stringify(currentPageRef - 1)
				);
				const backClearCrumbs = pathBreadcrumbs.slice(
					0,
					currentPageRef - 1
				);

				// Eliminar el objeto del arreglo
				setPathBreadcrumbs(backClearCrumbs);
				window.sessionStorage.setItem(
					"pathBreadcrumbs",
					JSON.stringify(backClearCrumbs)
				);
			}
		};
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	//hadleDelBreadcrumb Elimina los items y carga la pagina solicitada
	const hadleDelBreadcrumb = (e) => {
		e.preventDefault();

		//Buscamos el indice de target pulsado
		const indexInit = pathBreadcrumbs.findIndex(
			(objeto) => objeto.name === e.currentTarget.textContent
		);

		//creamos la variable donce almacenaremos el array filtrado
		// limpiamos el array y lo almacenamos
		if (indexInit !== -1) {
			clearCrumbs = pathBreadcrumbs.slice(0, indexInit + 1);
		}

		//guardamos el array filtrado en el state y el storage
		window.sessionStorage.setItem(
			"pathBreadcrumbs",
			JSON.stringify(clearCrumbs)
		);
		setPathBreadcrumbs(clearCrumbs);
	};

	return (
		<>
			{
				<div className="contentBreadcrumb">
					<ul className="df-s plr4-s">
						{Object.entries(pathBreadcrumbs)?.map(([key, value]) => (
							<React.Fragment key={value.name}>
								{value.path !== pathLocation ? (
									<li
										className="itemBreadcrumb df-s jcc-s acc-s"
										onClick={hadleDelBreadcrumb}
										name={value.name}
									>
										<Link to={value.path} name={value.name}>
											{value.name}
										</Link>
										<div className="itemBreadcrumb dg-s acc-s jccc-s">
											<div className="arrow"></div>
										</div>
									</li>
								) : (
									<li
										className="itemBreadcrumb itemBreadcrumb_active df-s jcc-s acc-s"
										id={key}
									>
										{value.name}
									</li>
								)}
							</React.Fragment>
						))}
					</ul>
				</div>
			}
		</>
	);
};

export default Breadcrumb;
