import React, { useContext } from "react";
import Header from "../../components/header/Header";
import Dash from "../../components/img/Dash";
import Download from "../../components/img/Download";
import { Link } from "react-router-dom";
import Users from "../../components/img/Users";
import ModalLoading from "../../components/modals/modalloading/ModalLoading";
import LoadingModal from "../component/authorizationcomponent/LoadingModal";
import RequestContext from "../../context/RequestContext";
import useUser from "../../hook/useUser";

const DashBoardPage = () => {
	const { loading, message, errorHttp } = useContext(RequestContext);
	const { setLoading } = useUser();

	return (
		<div className="mainIntranet">
			{(loading || errorHttp) && (
				<ModalLoading
					elementById="modal"
					loading={loading}
					setLoading={setLoading}
				>
					<LoadingModal message={message} titleModal="Cargando" />
				</ModalLoading>
			)}
			<Header />
			<div className="contentIntranet">
				{/* <NavIntranet /> */}
				{/* <div className="contentOrganization">
						<h3 className="pt16 pb32 center-cross big-text">
							Dashboard
						</h3>
					</div> */}
				<div className="dash-card">
					<div className="dash-image">
						<Dash />
					</div>
					<div className="dash-details">
						<h2 className="dash-title">Códigos</h2>
						<p className="dash-description">
							Accesos para quadstatistic app
						</p>
						<Link to="/authorizations" className="button">
							Acceder
						</Link>
					</div>
				</div>
				<div className="dash-card">
					<div className="dash-image">
						<Download />
					</div>
					<div className="dash-details">
						<h2 className="dash-title">Descargas</h2>
						<p className="dash-description">
							Descarga de la app y manuales
						</p>
						<Link to="/download" className="button">
							Acceder
						</Link>
					</div>
				</div>
				<div className="dash-card">
					<div className="dash-image">
						<Users />
					</div>
					<div className="dash-details">
						<h2 className="dash-title">Usuarios</h2>
						<p className="dash-description">
							Gestión de usuarios de la organización
						</p>
						<Link to="/user" className="button">
							Acceder
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
};

export default DashBoardPage;
