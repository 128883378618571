import React from "react";
import { useParams } from "react-router-dom";
import { LOGO_NOT_FOUND, URL_IMG } from "../../../const/Const";

const LocalCardWtihColor = ({ colourteam, teamname, logo, teamshort }) => {
	const { idOrganization } = useParams();

	const stylecss = {
		color: {
			backgroundColor: "#" + colourteam,
		},
	};
	return (
		<>
			<div className="card p0-s">
				<div className="card__color" style={stylecss.color}></div>
				<div className="card__logo">
					<img
						src={
							logo === null || ""
								? LOGO_NOT_FOUND
								: `${URL_IMG}/organizations/${idOrganization}/teams/${teamshort}/${logo}`
						}
						alt="Logo equipo local"
						className="w16px-s-i w48px-ipad-i"
					/>
				</div>
				<div className="card__name is-none-to-lg">
					{teamname === null || "" ? "LOCAL" : teamname}
				</div>
				<div className="card__name is-none-from-lg">
					{teamshort === null || "" ? "LOCAL" : teamshort}
				</div>
			</div>
		</>
	);
};

export default LocalCardWtihColor;
