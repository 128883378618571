import React from "react";
import "./styles/bannerhome.scss";
import PropTypes from "prop-types";

const BannerHome = ({ pos, title, msn, imgsrc }) => {
	return (
		<div className="banner-content separator">
			<div
				className={`text-content w100-s w49-lg ${
					pos === "izda" ? "izda-position" : "dcha-position"
				}`}
			>
				<div className="content-title">
					<h2 className="title fw100-s">{title}</h2>
				</div>
				<div className="content-msn">
					<p className="message">{msn}</p>
				</div>
			</div>
			<div
				className={`image-content w100-s w49-lg ${
					pos === "izda" ? "dcha-position" : "izda-position"
				}`}
			>
				<img src={imgsrc} alt={title} />
			</div>
		</div>
	);
};

BannerHome.propTypes = {
	pos: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	msn: PropTypes.string.isRequired,
	imgsrc: PropTypes.string.isRequired,
};

export default BannerHome;
