import React, { useState } from "react";
import { IMAGE_NOT_FOUND } from "../../const/Const";

const RoundImage = ({ url, altImgText, leader }) => {
	const [imageLoaded, setImageLoaded] = useState(true);

	const handleErrorImg = (e) => {
		setImageLoaded(false);
		e.target.src = IMAGE_NOT_FOUND;
		e.target.alt = "Image not found";
	};

	return (
		<div
			className={
				imageLoaded
					? " imageContent imageContent__roundFrame"
					: "imageContent"
			}
		>
			<img
				src={!imageLoaded ? IMAGE_NOT_FOUND : url}
				onError={handleErrorImg}
				alt={altImgText}
				className={leader ? "picture" : "picture w32px-s-i h32px-s-i"}
				loading="lazy"
			/>
		</div>
	);
};
export default RoundImage;
