// Entornos de trabajo de la web
const PRO = "https://quadstatistics20.clickerti.es/"; //Entorno de Producción
const PRE = "https://pre.quadstatistics20.clickerti.es/"; // Entorno de Pre-Producción
const DEV = "https://dev.quadstatistics20.clickerti.es/"; // Entorno de Pre-Producción
const LOCAL = "http://127.0.0.1:8000/"; // Entorno de desarrollo local

//----***************************************----//
//----***************************************----//
//----***************************************----//
//---- ** VARIABLES DE CONTROL DE DESTINO ** ----//

//Esta es la variabla que hay que cambiar para cambiar el entorno de la web
export const URL_DOMIAN = PRO; //PRO | PRE | DEV | LOCAL

// Esta es la varible que hay que cambiar para que el widget
// encuentre la home especifica para el widget
export const OUTBOUND_WEB = "web"; // web | widget

//----***************************************----//
//----***************************************----//
//----***************************************----//
//----***************************************----//

//URL principal
export const URL_BASIC_SERVER =
	URL_DOMIAN === LOCAL
		? URL_DOMIAN + "api/v1/"
		: URL_DOMIAN + "public/api/v1/";

//Imagenes
export const URL_IMG =
	URL_DOMIAN !== PRO
		? DEV + "public/img"
		: PRO + "public/img";

//styles
export const CSS_GET_ORG = URL_BASIC_SERVER + "style/org";

//Login
export const LOGIN = URL_BASIC_SERVER + "login/";

//ALL get Data
export const WEB_ALL = URL_BASIC_SERVER + "all/get/webdata";

//versionApp
export const VERSION_APP = URL_BASIC_SERVER + "version/get";

// Championship
export const SEASONS_GET = URL_BASIC_SERVER + "seasons/remote/get";

// Authorization
export const MATCH_AUTHORIZATION = URL_BASIC_SERVER + "matchs/restnetwork";

// Authorization por partido
export const AUTHORIZATION_CODE = URL_BASIC_SERVER + "authorization_code";

//Get User deled remote DB
export const RESTNETWORK_GET_USER =
	URL_BASIC_SERVER + "user/restnetwork/get/by";

export const USER_BY_ORGANIZATION = URL_BASIC_SERVER + "user/get/by";

export const CONTACT_FORM = URL_BASIC_SERVER + "mail/contact/form";

//Champioships get
// export const CHAMPIONSHIP_GET = URL_BASIC_SERVER + "championships/get";
// export const CHAMPIONSHIP_GET = URL_BASIC_SERVER + 'championships/remote/get/'

//Macth-code
// export const MATCH = URL_BASIC_SERVER + "matchs/";

//Macth-code
// export const LEADER_RANKING = URL_BASIC_SERVER + "league_ranking/remote/get/";

//Macth List
// export const MATCH_LIST = URL_BASIC_SERVER + "matchs/list/";

//Matchstatistics
// export const MATCHSTATISTICS_GET = URL_BASIC_SERVER + "match_statistics/get/";

//getByTeamSum
// export const GET_BY_TEAM_SUM = URL_BASIC_SERVER + "match_statistics/sum/";

//getByPlayers
// export const GET_BY_PLAYERS =
// 	URL_BASIC_SERVER + "match_statistics/getByPlayers/";

//Matchstatitics por codigo
// export const MATCHSTATISTICS_BY_CODE =
// 	URL_BASIC_SERVER + "match_statistics/by/";

//get data del Score
// export const MATCHHISTORIES_GET_SCORE =
// 	URL_BASIC_SERVER + "match_histories/getscore/";

// History
// export const HISTORY_BY_MATCH =
// 	URL_BASIC_SERVER + "match_histories/gethistory/";

//Techical
// export const GET_TECHNICAL = URL_BASIC_SERVER + "technical_teams/get/byteam/";

//Refeere
// export const GET_REFEREE_BY_MATCH =
// 	URL_BASIC_SERVER + "referee_commissioners/get/";

//images
//Not Found image
export const IMAGE_NOT_FOUND = URL_IMG + "/not-found/image-not-found.jpg";

//Not Found logo
export const LOGO_NOT_FOUND = URL_IMG + "/not-found/logo-not-found.png";
